import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import Bugsnag from "@bugsnag/js";
import { Observable, tap } from "rxjs";
import { JWTService } from "../services/jwt.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private _jwtService: JWTService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestTime = Date.now();

    return next.handle(request).pipe(
      tap({
        next: () => {
          const responseTime = Date.now();
          const duration = responseTime - requestTime;
          const isPip = this._jwtService.isPip();
          const sessionId = this._jwtService.getJWT()?.sid;

          const logSlow = (durationThreshold: number, message: string): void => {
            if (duration > durationThreshold) {
              Bugsnag.leaveBreadcrumb(message, { url: request.url, duration, sid: sessionId });
              Bugsnag.notify(new Error(message));
            }
          };

          logSlow(isPip ? 2500 : 10000, `SlowHttp${isPip ? " for PiP" : ""}`);
        },
        error: (error: HttpErrorResponse) => {
          Bugsnag.leaveBreadcrumb("HttpError", { url: error.url, status: error.status, message: error.message, error }); // Leave details about the error in the breadcrumb
          Bugsnag.notify(new Error(error.status === 503 ? "HttpTimeoutError" : "HttpError"));
        },
      })
    );
  }
}
