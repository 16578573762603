import { Injectable } from "@angular/core";
import { GRAPHQL_OPERATION_NAMES, HttpService } from "./http.service";
import { Observable, from, map, of, switchMap, tap } from "rxjs";
import { BrowserCrypto } from "@shared/browser-crypto";
import { CacheService } from "./cache.service";
import { JWTService } from "./jwt.service";
import { FeatureFlagsService } from "./feature-flags.service";
import { E_PatientFeatures } from "@backend/common/enums/feature-flags.enum";

type T_CachedHttpQueryOptions = {
  ttl?: number;
  /**
   * Whether or not to cache the result using the patient's ID, SID and access level. This is useful for caching queries that are not patient-specific such as the common query but
   * still allowing the queries which are patient-specific to be cached against the patient (e.g. bookable appointments where new vs existing patient is important)
   */
  notPatientSpecific?: boolean;
};

@Injectable({
  providedIn: "root",
})
export class CachedHttpService implements Pick<HttpService, "query" | "send"> {
  constructor(
    private _httpService: HttpService,
    private _cacheService: CacheService,
    private _jwtService: JWTService,
    private _featureFlagsService: FeatureFlagsService
  ) {}

  /**
   * Caches the result of a REST call in session storage so that it can be re-used if the patient or application reloads the page
   *
   * WARNING Do not use this if the REST call will return any patient PII
   *
   * @param url REST URL
   * @param options Options
   * @param cacheOptions Cache options
   *
   * @returns Observable
   */
  public send<T extends Record<string, any>>(url: string, options: any = {}, cacheOptions: T_CachedHttpQueryOptions = {}): Observable<T | any> {
    const queryObservable = () => this._httpService.send<T>(url, options);
    const cacheKeyObservable = () => this._getHashedKey(cacheOptions, url);
    return this._handleRequest<T>(queryObservable, cacheKeyObservable, cacheOptions);
  }

  /**
   * Caches the result of a GraphQL query in session storage so that it can be re-used if the patient or application reloads the page
   *
   * WARNING Do not use this if the GraphQL query will return any patient PII
   *
   * @param operationName Name of the GraphQL operation
   * @param query GraphQL query
   * @param cacheOptions Cache options
   *
   * @returns Observable
   */
  public query<T extends Record<string, any>>(
    operationName: GRAPHQL_OPERATION_NAMES,
    query: string,
    cacheOptions: T_CachedHttpQueryOptions = {}
  ): Observable<T | any> {
    const queryObservable = () => this._httpService.query<T>(operationName, query);
    const cacheKeyObservable = () => this._getHashedKey(cacheOptions, query, operationName);
    return this._handleRequest<T>(queryObservable, cacheKeyObservable, cacheOptions);
  }

  private _handleRequest<T extends Record<string, any>>(
    queryObservable: () => Observable<T>,
    cacheKeyObservable: () => Observable<string | null>,
    cacheOptions: T_CachedHttpQueryOptions
  ): Observable<T> {
    const { ttl = 300 } = cacheOptions; // Default the cache TTL to 5 minutes
    const isSQLQueryReductionEnabled = this._featureFlagsService.getFeatureFlagValue(E_PatientFeatures.SQL_QUERY_REDUCTION, false);

    if (!isSQLQueryReductionEnabled) {
      // The browser doesn't support SHA-256 hashing, so we can't cache the result
      return queryObservable();
    }

    return cacheKeyObservable().pipe(
      switchMap((cacheKey) => {
        if (!cacheKey) {
          return queryObservable();
        }

        const cachedResult = this._cacheService.getJsonSession<any>(cacheKey);
        if (cachedResult) {
          // There's a valid cached result, so return it
          return of(cachedResult);
        }

        // There's no cached result, so query the server and cache the result
        return queryObservable().pipe(
          tap((result) => {
            this._cacheService.setJsonSession(cacheKey, result, Date.now() + ttl * 1000);
          })
        );
      })
    );
  }

  private _generateCacheKey(cacheOptions: T_CachedHttpQueryOptions, key: string): string {
    const { notPatientSpecific } = cacheOptions;

    if (notPatientSpecific) return key;

    return `${key}.${this._jwtService.getJWT("sid")}.${this._jwtService.getJWT("access_level")}.${this._jwtService.getJWT("patient_id") || "public"}`;
  }

  private _getHashedKey(cacheOptions: T_CachedHttpQueryOptions, key: string, operationName?: GRAPHQL_OPERATION_NAMES): Observable<string | null> {
    const cacheKey = this._generateCacheKey(cacheOptions, key);

    return from(BrowserCrypto.sha256(cacheKey)).pipe(
      map((hash) => {
        if (!hash) {
          return null;
        }

        return operationName ? `graphql-cache:${operationName}:${hash}` : `rest-cache:${hash}`;
      })
    );
  }
}
