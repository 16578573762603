import { Injectable } from "@angular/core";
import { Observable, lastValueFrom, map } from "rxjs";
import { HttpService } from "./http.service";
import { JWTService } from "./jwt.service";
import Bugsnag from "@bugsnag/js";
import { CacheService } from "./cache.service";
import { GAService } from "./ga.service";
import { Constants } from "src/constants";
import { WindowService } from "./window.service";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  constructor(
    private _httpService: HttpService,
    private _jwtService: JWTService,
    private _cacheService: CacheService,
    private _gaService: GAService,
    private _windowService: WindowService
  ) {
    this._jwtService.onSessionIdChanged.subscribe(() => {
      this._setSessionId();
    });
  }

  public async init(): Promise<void> {
    try {
      const jwt = await lastValueFrom(this._getSession());

      if (!jwt) return;

      const existingJWT = this._jwtService.getJWTString();

      if (!!existingJWT) {
        if (existingJWT !== jwt) Bugsnag.notify(new Error("JWT stored in local storage does not match the one returned from the server"));

        return;
      }

      // TODO: once we're happy that this is working (i.e. no Bugsnag errors are being reported) we can set the token in memory and
      //       prevent it being stored the local storage
    } catch (error) {
      Bugsnag.notify(error);
    }
  }

  public async clear(): Promise<void> {
    try {
      await lastValueFrom(
        this._httpService.send(`/sessions`, {
          method: "DELETE",
        })
      );
    } catch (error) {
      console.error("error clearing session", error);
      Bugsnag.notify(error);
    }
    this._jwtService.delete();
  }

  public async logout(): Promise<void> {
    this._cacheService.deleteSession(Constants.PATIENT_ACTIONS_SESSION_STORAGE_KEY);
    Bugsnag.leaveBreadcrumb("Sign out");
    this._gaService.action("signout");
    this._cacheService.clearSession();
    await this.clear();
    this._windowService.href = "/signout";
  }

  private _getSession(): Observable<string | null> {
    return this._httpService
      .send(`/sessions`, {
        method: "GET",
      })
      .pipe(map((response: { jwt: string } | null) => response?.jwt || null));
  }

  private _setSession(): Observable<string | any[] | null> {
    return this._httpService.send(`/sessions`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this._jwtService.getJWTString()}`,
      },
    });
  }

  private _setSessionId(): void {
    try {
      this._setSession().subscribe();
    } catch (error) {
      Bugsnag.notify(error);
    }
  }
}
