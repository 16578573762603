export class BookableAppointmentPractitionerBase {
  public active: boolean;
  public biography?: string;
  public colour: string;
  public duration: number;
  public first_name: string;
  public id: string;
  public image_url: string | null;
  public is_preferred: boolean;
  public job_title: string;
  public last_name: string;
  public role: string;
  public title: string;
}

export class BookableAppointmentBase {
  public appointment_type_category_id: string;
  public appointment_type_category_name: string;
  public appointment_type_id: string;
  public appointment_type_name: string;
  public appointment_type_reason: string;
  public appointment_type_consultation_type: string;
  public default_duration: number;
  public deposit?: number | null;
  public description: string;
  public fixed_price: number;
  public id: string;
  public name: string;
  public payment_provider_logo_url: string;
  public payment_provider_id: string;
  public payment_provider_name: string;
  public plan_allowance_amount: number | null;
  public plan_allowance_interval: number | null;
  public plan_discount: number | null;
  public plan_id: string | null;
  public plan_name: string | null;
  public practitioners: Array<BookableAppointmentPractitionerBase>;
  public practitioners_locked: boolean;
  public price: number | null;
  public site_id: string;
  public site_name: string;
  public site_address_line_1: string;
  public site_address_line_2: string;
  public site_address_line_3: string;
  public site_town: string;
  public site_county: string;
  public site_postcode: string;

  public get total_deposit(): number | null {
    if (!this.deposit) return null;
    if (this.plan_discount) {
      return Math.round(this.deposit * (1 - parseFloat(this.plan_discount.toString())));
    }
    return this.deposit;
  }

  public get total_price(): number | null {
    const price = this.fixed_price || this.price;
    if (price === null) return null;
    if (!price) return 0;
    if (this.plan_discount) {
      return Math.round(price * (1 - parseFloat(this.plan_discount.toString())));
    }
    return price;
  }
}

export class BookableAppointmentCategoryBase {
  public dental_appointment: boolean;
  public description: string;
  public for_new_patients: boolean;
  public hygiene_appointment: boolean;
  public id: string;
  public name: string;
  public order: number;
  public supports_nhs: boolean;
  public supports_routine_appointments: boolean;
  public site_appointment_types: Array<BookableAppointmentBase>;
}

export class BookableAppointmentSiteBase {
  public id: string;
  public name: string;
  public phone_number: string;

  public address_line_1: string;
  public address_line_2: string;
  public address_line_3: string;
  public town: string;
  public county: string;
  public postcode: string;

  public default_payment_plan_id: string;

  public appointment_categories: Array<BookableAppointmentCategoryBase>;
}
