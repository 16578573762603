import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from "@angular/router";
import { BookableAppointmentsAnimationResolver } from "../shared/resolvers/bookable-appointments.resolver";
import { PatientsService } from "../shared/services/patients.service";
import { JWTService } from "../shared/services/jwt.service";
import { BookableApptsService } from "../shared/services/bookable-appts.service";

@Injectable()
export class BookableAppointmentsAuthGuard {
  constructor(private _jwtService: JWTService, private _patientsService: PatientsService, private _bookableApptsService: BookableApptsService) {}

  public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Show the DOB verification page if the patient is unauthenticated and doesn't have a DOB and hasn't selected a slot. Checking the selected slot
    // handles cases where new patients click the back button on the Stripe checkout
    if (this._jwtService.isPatientUnauthenticated() && !this._hasEnteredDob && !this._isReturningFromStripe) {
      // Location change must not be skipped otherwise state will not work
      this._bookableApptsService.navigate("dob-verification", null, false, { state: { returnUrl: state.url } });

      return false;
    }

    return true;
  }

  private get _hasEnteredDob(): boolean {
    return !!this._patientsService.patientInfo?.date_of_birth;
  }

  private get _isReturningFromStripe(): boolean {
    return !!this._bookableApptsService.selectedSlot;
  }
}

export const BOOKABLE_APPTS_ROUTES: Routes = [
  {
    path: "",
    loadComponent: () => import("./bookable-appointments.component").then((m) => m.BookableAppointmentsComponent),
    resolve: {
      animation: BookableAppointmentsAnimationResolver,
    },
    canActivate: [BookableAppointmentsAuthGuard],
  },
  {
    path: "dob-verification",
    loadComponent: () => import("./booking-dob-verification/booking-dob-verification.component").then((m) => m.BookingDobVerificationComponent),
  },
  {
    path: "location",
    loadComponent: () => import("./location/location.component").then((m) => m.BookableAppointmentLocationComponent),
    resolve: {
      animation: BookableAppointmentsAnimationResolver,
    },
    canActivate: [BookableAppointmentsAuthGuard],
  },
  {
    path: "category",
    loadComponent: () => import("./category/category.component").then((m) => m.BookableAppointmentsCategoryComponent),
    resolve: {
      animation: BookableAppointmentsAnimationResolver,
    },
    canActivate: [BookableAppointmentsAuthGuard],
  },
  {
    path: "appointment",
    loadComponent: () => import("./site-appointment/site-appointment.component").then((m) => m.BookableAppointmentSiteAppointmentComponent),
    resolve: {
      animation: BookableAppointmentsAnimationResolver,
    },
    canActivate: [BookableAppointmentsAuthGuard],
  },
  {
    path: "new-patient",
    loadComponent: () => import("../new-patient/new-patient-form/new-patient-form.component").then((m) => m.NewPatientFormComponent),
    resolve: {
      animation: BookableAppointmentsAnimationResolver,
    },
    canActivate: [BookableAppointmentsAuthGuard],
  },
  {
    path: "new-patient/verify",
    loadComponent: () => import("../new-patient/new-patient-sms-verify/new-patient-sms-verify.component").then((m) => m.NewPatientSmsVerifyComponent),
    resolve: {
      animation: BookableAppointmentsAnimationResolver,
    },
    canActivate: [BookableAppointmentsAuthGuard],
  },
  {
    path: "practitioner",
    loadComponent: () => import("./practitioner/practitioner.component").then((m) => m.BookableAppointmentPractitionerComponent),
    resolve: {
      animation: BookableAppointmentsAnimationResolver,
    },
    canActivate: [BookableAppointmentsAuthGuard],
  },
  {
    path: "additional-appointments",
    loadComponent: () => import("./site-appointment/site-appointment.component").then((m) => m.BookableAppointmentSiteAppointmentComponent),
    resolve: {
      animation: BookableAppointmentsAnimationResolver,
    },
    data: {
      additionalAppointments: true,
    },
    canActivate: [BookableAppointmentsAuthGuard],
  },
  /*
   * New variants - all but one will be removed once we have determined a winner
   */
  {
    path: "availability",
    loadComponent: () => import("./availability/availability.component").then((m) => m.AvailabilityComponent),
    resolve: {
      animation: BookableAppointmentsAnimationResolver,
    },
    canActivate: [BookableAppointmentsAuthGuard],
  },
  {
    path: "checkout-complete",
    loadComponent: () => import("./checkout-complete/checkout-complete.component").then((m) => m.CheckoutCompleteComponent),
    resolve: {
      animation: BookableAppointmentsAnimationResolver,
    },
  },
  {
    path: "success",
    loadComponent: () => import("./booking-success/booking-success.component").then((m) => m.BookingSuccessComponent),
    resolve: {
      animation: BookableAppointmentsAnimationResolver,
    },
  },
  {
    path: "cancel-checkout",
    loadComponent: () => import("./cancel-checkout/cancel-checkout.component").then((m) => m.CancelCheckoutComponent),
  },
  {
    path: "error",
    loadComponent: () => import("./booking-error/booking-error.component").then((m) => m.BookingErrorComponent),
  },
];
