import { Injectable } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import Bugsnag from "@bugsnag/js";
import { PipMetrics } from "@backend/common/analytics/pip-metrics";
import { AnalyticsService } from "../analytics.service";
import { BrandService } from "../brand.service";
import { PortalInPracticeService } from "../portal-in-practice.service";

@Injectable({
  providedIn: "root",
})
export class PipMetricsService {
  private _properties = new PipMetrics.MetricProperties();

  constructor(
    private _analyticsService: AnalyticsService,
    private _brandService: BrandService,
    private _deviceDetectorService: DeviceDetectorService,
    private _pipService: PortalInPracticeService
  ) {}

  public trackDevicePaired(): void {
    try {
      this._setProperties();
      this._analyticsService.track(
        new PipMetrics.DevicePaired(
          this._brandService.restrictedSiteId,
          this._properties.os,
          this._properties.deviceName,
          this._properties.browser,
          this._properties.mode
        )
      );
    } catch (error) {
      this._errorAndNotifyBugsnag("error tracking device paired metric", error);
    }
  }

  public trackCheckInStarted(): void {
    try {
      this._setProperties();
      this._analyticsService.track(
        new PipMetrics.CheckInStarted(
          this._brandService.restrictedSiteId,
          this._properties.os,
          this._properties.deviceName,
          this._properties.browser,
          this._properties.mode
        )
      );
    } catch (error) {
      this._errorAndNotifyBugsnag("error tracking check in started metric", error);
    }
  }

  public trackCheckInDobEntered(): void {
    try {
      this._setProperties();
      this._analyticsService.track(
        new PipMetrics.CheckInDobSubmitted(
          this._brandService.restrictedSiteId,
          this._properties.os,
          this._properties.deviceName,
          this._properties.browser,
          this._properties.mode
        )
      );
    } catch (error) {
      this._errorAndNotifyBugsnag("error tracking dob submitted metric", error);
    }
  }

  public trackCheckInDaySelected(): void {
    try {
      this._setProperties();
      this._analyticsService.track(
        new PipMetrics.CheckInDaySelected(
          this._brandService.restrictedSiteId,
          this._properties.os,
          this._properties.deviceName,
          this._properties.browser,
          this._properties.mode
        )
      );
    } catch (error) {
      this._errorAndNotifyBugsnag("error tracking check in day selected metric", error);
    }
  }

  public trackCheckInMonthSelected(): void {
    try {
      this._setProperties();
      this._analyticsService.track(
        new PipMetrics.CheckInMonthSelected(
          this._brandService.restrictedSiteId,
          this._properties.os,
          this._properties.deviceName,
          this._properties.browser,
          this._properties.mode
        )
      );
    } catch (error) {
      this._errorAndNotifyBugsnag("error tracking check in month selected metric", error);
    }
  }

  public trackCheckInYearEntered(): void {
    try {
      this._setProperties();
      this._analyticsService.track(
        new PipMetrics.CheckInYearEntered(
          this._brandService.restrictedSiteId,
          this._properties.os,
          this._properties.deviceName,
          this._properties.browser,
          this._properties.mode
        )
      );
    } catch (error) {
      this._errorAndNotifyBugsnag("error tracking check in year entered metric", error);
    }
  }

  public trackCheckInSurnameEntered(): void {
    try {
      this._setProperties();
      this._analyticsService.track(
        new PipMetrics.CheckInSurnameEntered(
          this._brandService.restrictedSiteId,
          this._properties.os,
          this._properties.deviceName,
          this._properties.browser,
          this._properties.mode
        )
      );
    } catch (error) {
      this._errorAndNotifyBugsnag("error tracking check in surname entered metric", error);
    }
  }

  public trackCheckInPatientNotFound(): void {
    try {
      this._setProperties();
      this._analyticsService.track(
        new PipMetrics.CheckInPatientNotFound(
          this._brandService.restrictedSiteId,
          this._properties.os,
          this._properties.deviceName,
          this._properties.browser,
          this._properties.mode
        )
      );
    } catch (error) {
      this._errorAndNotifyBugsnag("error tracking check in patient not found metric", error);
    }
  }

  public trackCheckInFirstNameEntered(): void {
    try {
      this._setProperties();
      this._analyticsService.track(
        new PipMetrics.CheckInFirstNameEntered(
          this._brandService.restrictedSiteId,
          this._properties.os,
          this._properties.deviceName,
          this._properties.browser,
          this._properties.mode
        )
      );
    } catch (error) {
      this._errorAndNotifyBugsnag("error tracking check in first name entered metric", error);
    }
  }

  public trackCheckInDeviceLocked(): void {
    try {
      this._setProperties();
      this._analyticsService.track(
        new PipMetrics.DeviceLocked(
          this._brandService.restrictedSiteId,
          this._properties.os,
          this._properties.deviceName,
          this._properties.browser,
          this._properties.mode
        )
      );
    } catch (error) {
      this._errorAndNotifyBugsnag("error tracking check in device locked metric", error);
    }
  }

  public trackCheckInDeviceUnlocked(): void {
    // TODO: add once unlocking is implemented
    try {
      this._setProperties();
      this._analyticsService.track(
        new PipMetrics.DeviceUnlocked(
          this._brandService.restrictedSiteId,
          this._properties.os,
          this._properties.deviceName,
          this._properties.browser,
          this._properties.mode
        )
      );
    } catch (error) {
      this._errorAndNotifyBugsnag("error tracking check in device unlocked metric", error);
    }
  }

  public trackCheckedIn(): void {
    try {
      this._setProperties();
      this._analyticsService.track(
        new PipMetrics.CheckedIn(
          this._brandService.restrictedSiteId,
          this._properties.os,
          this._properties.deviceName,
          this._properties.browser,
          this._properties.mode
        )
      );
    } catch (error) {
      this._errorAndNotifyBugsnag("error tracking check in device unlocked metric", error);
    }
  }

  private _setProperties(): void {
    const deviceInfo = this._deviceDetectorService.getDeviceInfo();

    this._properties.os = deviceInfo.os;
    this._properties.deviceName = deviceInfo.device;
    this._properties.browser = deviceInfo.browser;
    this._properties.mode = this._pipService.serviceMode;
  }

  private _errorAndNotifyBugsnag(message: string, e: Error) {
    Bugsnag.notify(message);
    console.error(message, e);
  }
}
