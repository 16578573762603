import { Injectable } from "@angular/core";
import { Appointments } from "@apis/_core/types/appointments.interface";
import { BehaviorSubject, firstValueFrom, lastValueFrom, Observable, of, race, Subject, Subscription, timer } from "rxjs";
import { catchError, distinctUntilChanged, filter, map, switchMap, take, tap } from "rxjs/operators";
import {
  AppointmentEntry,
  E_AppointmentEventActions as E_Appointment_Event,
  AppointmentEvents,
  E_AppointmentEventActions,
  AppointmentPractitionerEntry,
} from "src/app/data_model/appointment";
import { SubSink } from "subsink";
import { GAService } from "./ga.service";
import { GRAPHQL_OPERATION_NAMES, HttpService } from "./http.service";
import { JWTService } from "./jwt.service";
import { PatientsService } from "./patients.service";
import { I_SelectedAppointmentSlot } from "src/app/bookable-appointments/availability/slot-basketless/slot.component";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { BookableAppointmentEntry } from "src/app/data_model/bookable-appointment";
import { E_Appointments_When, E_Appointment_Error_Codes, AppointmentBase, AppointmentPractitionerBase } from "@backend/graph/appointments/appointment-base";
import { LocationService } from "./location.service";
import { APPOINTMENT_BOOKING } from "@shared/constants";
import { AppointmentAnalyticsService } from "./analytics/appointment-analytics.service";
import Bugsnag from "@bugsnag/js";
import { CacheService } from "./cache.service";
import { PatientBase } from "@backend/graph/patients/patient-base";

export type CheckInAppointmentDetails = {
  practitioner: Pick<
    AppointmentPractitionerBase,
    | "site_name"
    | "first_name"
    | "last_name"
    | "image_url"
    | "site_address_line_1"
    | "site_address_line_2"
    | "site_address_line_3"
    | "site_county"
    | "site_postcode"
    | "role"
  >;
} & {
  patient: Pick<PatientBase, "first_name">;
} & Pick<AppointmentBase, "start_time" | "duration" | "reason" | "system_state">;

dayjs.extend(advancedFormat);

const COMMON_APPOINTMENT_ITEMS = `
  duration
  id
  patient_id
  reason
  start_time
  state
  system_state
  treatment_description
  upcoming
  practitioner
  {
    id
    active
    first_name
    last_name
    biography
    image_url
    role
    site_name
    site_phone_number
    title

    site_address_line_1
    site_address_line_2
    site_address_line_3
    site_town
    site_county
    site_postcode
  }`;

export const FUTURE_QUERY = `{
  appointments(when:"${E_Appointments_When.FUTURE}") {
    items
    {
      cancellation_info
      {
        amount
        can_cancel
        can_cancel_before
        cancellation_screen
        charge_after
        charge_if_less_than_hours
        minutes_left_to_cancel
        minutes_left_to_cancel_free
      }
      ${COMMON_APPOINTMENT_ITEMS}
    }
}
}`;

export const PAST_QUERY = `{
  appointments(when:"${E_Appointments_When.PAST}") {
    items
    {
      ${COMMON_APPOINTMENT_ITEMS}
    }
}
}`;
export enum E_Appointment_Updated {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

type T_HeldAppointment = I_SelectedAppointmentSlot & { id: number };

export const HELD_APPOINTMENTS_KEY = "heldAppointments";

export class OnAppointmentsChangeEvent {
  public status: E_Appointment_Updated;
  public when?: E_Appointments_When | null;
  public appointments?: Array<AppointmentEntry>;
}
@Injectable({
  providedIn: "root",
})
export class AppointmentsService {
  private _subs = new SubSink();

  private _heldAppointments = new Array<T_HeldAppointment>();
  public onAppointmentsChanged: BehaviorSubject<OnAppointmentsChangeEvent | null> = new BehaviorSubject(null);
  public onAppointmentStatusEvents: BehaviorSubject<AppointmentEvents | null> = new BehaviorSubject(null);
  public onBookingError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _getAppointmentsState = new Subject<E_Appointments_When | null>();
  private _appointments: Array<AppointmentEntry>;
  private _keepAliveSubscription: Subscription;
  private _heldBookableAppointments: Array<BookableAppointmentEntry>;
  private _heldSelectedAppointmentSlots: Array<I_SelectedAppointmentSlot>;

  constructor(
    private _httpService: HttpService,
    private _gAService: GAService,
    private _jwtService: JWTService,
    private _patientService: PatientsService,
    private _locationService: LocationService,
    private _appointmentAnalyticsService: AppointmentAnalyticsService,
    private _cacheService: CacheService
  ) {
    this._listenForGetAppointments();

    this._heldAppointments = JSON.parse(this._cacheService.getSession(HELD_APPOINTMENTS_KEY) || "[]");

    Bugsnag.leaveBreadcrumb("Loaded held appointments", { appointments: this._heldAppointments });
  }

  resolve(): Observable<any> | Promise<any> | any {
    return true;
  }

  public get heldAppointments(): Array<T_HeldAppointment> {
    return this._heldAppointments;
  }

  public set heldAppointments(heldAppointments: Array<T_HeldAppointment>) {
    this._heldAppointments = heldAppointments;
  }

  public get hasHeldAppointments(): boolean {
    return this._heldAppointments.length > 0;
  }

  public async extendHeldAppointments(): Promise<void> {
    try {
      if (!this.hasHeldAppointments) return;

      await lastValueFrom(
        this._httpService.mutation(
          "extendHeldAppointments",
          `{
          extendHeldAppointments(ids: ${JSON.stringify(this._heldAppointments.map((appt) => String(appt.id)))})
        }`
        )
      );
    } catch {
      // Ignore errors because the SQS job will handle the clean up
    }
  }

  public async clearHeldAppointments(): Promise<void> {
    Bugsnag.leaveBreadcrumb("Clearing held appointments", {
      count: this._heldAppointments.length,
    });

    this.clearKeepAlive();

    try {
      await lastValueFrom(
        this._httpService.mutation(
          "clearHeldAppointments",
          `{
        clearHeldAppointments
      }`
        )
      );
    } catch {
      // Ignore errors because the SQS job will handle the clean up
    } finally {
      this._clearHeldAppointments();

      if (this._heldBookableAppointments?.length) this._heldBookableAppointments.length = 0;
      if (this._heldSelectedAppointmentSlots?.length) this._heldSelectedAppointmentSlots.length = 0;
    }
  }

  /**
   * Holds appointments for 10 minutes to give the patient time to confirm and (if required) pay the deposit
   *
   * @param bookableAppointments Bookable appointments (adding BookableApptsService to this service causes a circular dependency)
   * @param selectedAppointmentSlots Select appointment slots
   */
  public async holdAppointments(
    bookableAppointments: Array<BookableAppointmentEntry>,
    selectedAppointmentSlots: Array<I_SelectedAppointmentSlot>,
    onError?: (errors: any) => void
  ): Promise<void> {
    await this.clearHeldAppointments();

    const appointments = new Array<I_SelectedAppointmentSlot>();
    const mutations = selectedAppointmentSlots.map((slot) => {
      const bookableAppointment = bookableAppointments.find((appt) => appt.id === slot.site_appointment_type_id);
      if (bookableAppointment) {
        const { appointment_type_reason: reason, id } = bookableAppointment;
        const appointment = {
          ...slot,
          reason,
          site_appointment_type_id: id,
          site_id: bookableAppointment.site_id,
        };

        appointments.push(appointment);

        return this._getCreateAppointmentMutation(appointment);
      }
    });

    const mutationsString = `{
      ${mutations
        .map((mutation, index) => {
          return `mut${index}: ${mutation} {
          id
        }`;
        })
        .join("\n")}
    }`;

    Bugsnag.leaveBreadcrumb("Holding appointment(s)", {
      mutationsString,
    });

    // Store function args for use in _checkHeldAppointmentsExist
    this._heldBookableAppointments = bookableAppointments;
    this._heldSelectedAppointmentSlots = selectedAppointmentSlots;

    try {
      const response = await lastValueFrom(this._httpService.mutation("createAppointment", mutationsString));

      if (response.errors) {
        if (onError) {
          onError(response.errors);
          return;
        } else {
          if (response.errors[0].extensions.code === E_Appointment_Error_Codes.CONFLICT) {
            this.onAppointmentStatusEvents.next({
              action: E_AppointmentEventActions.SLOT_UNAVAILABLE,
              slots: selectedAppointmentSlots,
            });
          }

          throw new Error();
        }
      }

      this._heldAppointments = [...Object.values(response.data as Record<string, { id: string }>).entries()].reduce<Array<T_HeldAppointment>>(
        (acc, [index, value]) => {
          acc.push({
            ...appointments[index],
            ...value,
          } as unknown as T_HeldAppointment);

          return acc;
        },
        []
      );

      this._cacheService.setSession(HELD_APPOINTMENTS_KEY, JSON.stringify(this._heldAppointments));

      Bugsnag.leaveBreadcrumb("Appointment(s) held", { appointments: this._heldAppointments });

      this._startKeepAlive();
    } catch (e) {
      console.error("failed to hold appointment", e);
      Bugsnag.notify(e);

      if (onError) onError([e]);

      this.onAppointmentStatusEvents.next({
        action: E_AppointmentEventActions.SLOT_UNAVAILABLE,
        slots: selectedAppointmentSlots,
      });
    }
  }

  public async bookAppointments(isNewPatient: boolean): Promise<Array<number>> {
    try {
      const response = await lastValueFrom(
        this._httpService.mutation(
          "finalizeAppointmentBooking",
          `{
        finalizeAppointmentBooking(
          is_new_patient: ${isNewPatient ? "true" : "false"},
          hostname: "${this._locationService.hostname}"
        ) {
          appointment_ids
        }
      }`
        )
      );

      if (response.errors?.length) {
        throw new Error();
      }

      // To trigger the patient actions to refetch
      this.onAppointmentStatusEvents.next({
        action: E_AppointmentEventActions.APPOINTMENTS_UPDATED,
      });

      return response.data.finalizeAppointmentBooking.appointment_ids.map((id) => Number(id));
    } catch (error) {
      Bugsnag.leaveBreadcrumb("Booking appointment(s) failed", {
        error,
      });
      Bugsnag.notify(error);

      this.onBookingError.next(true);

      throw new Error("Failed to book appointments");
    } finally {
      this._clearHeldAppointments();
    }
  }

  private _clearHeldAppointments(): void {
    this._heldAppointments.length = 0;
    this._cacheService.deleteSession(HELD_APPOINTMENTS_KEY);
  }

  private _getCreateAppointmentMutation(appointment: I_SelectedAppointmentSlot, patientId?: string, isNewPatient?: boolean): string {
    return `createAppointment(new_item: {
      site_id: "${appointment.site_id}"
      start_time: "${dayjs(appointment.start_time).format("YYYY-MM-DDTHH:mm:ssZ")}"
      finish_time: "${dayjs(appointment.start_time).add(appointment.duration, "minutes").format("YYYY-MM-DDTHH:mm:ssZ")}"
      reason: "${appointment.reason}"
      practitioner_id: ${Number(appointment.practitioner.id)}
      site_appointment_type_id: "${appointment.site_appointment_type_id}"
      ${patientId ? `patient_id: "${patientId}"` : ""}
      ${patientId ? `is_new_patient: ${isNewPatient ? "true" : "false"}` : ""}
    }, hostname: "${this._locationService.hostname}")`;
  }

  async getAppointments(when: E_Appointments_When, force?: boolean): Promise<void> {
    if (force) {
      // We can assume something has changed with the patients appointments so we should re-fetch the patient to ensure their stats are up to date
      await this._patientService.getPatient();
      // Reset the appointment state if we are forcing a refresh so that the observable distinctUntilChanged check passes
      this._getAppointmentsState.next(null);
    } else if (this._appointments) {
      this.onAppointmentsChanged.next({
        status: E_Appointment_Updated.SUCCESS,
        when,
        appointments: this._appointments,
      });
      return;
    }
    this._getAppointmentsState.next(when);
  }

  checkForCheckins(appointments) {
    const canCheckinFor: any = [];

    if (appointments && appointments.length) {
      appointments.forEach((a) => {
        switch (a.state) {
          case "FUTURE_CHECKIN_OPEN":
          case "ARRIVED":
            canCheckinFor.push(a);
            break;
          default:
        }
      });
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  addCommentToAppointment(payload: Appointments.Public.PreAppointmentComment.Post) {
    const { id: appointment_id, comment } = payload;
    this._subs.sink = this._httpService
      .post(`/appointments/v1/appointments/${appointment_id}/actions/addcomment`, {
        comment,
      })
      .subscribe(
        (response) => {
          const resp: Appointments.Public.PreAppointmentComment.PostHTTPResponse = response.data;
          if (resp?.code === "SUCCESSFULLY ADDED COMMENT") {
            this._gAService.error("appointment_addcomment");
            this.onAppointmentStatusEvents.next({
              action: E_Appointment_Event.SUCCESSFULLY_ADDED_COMMENT,
            });
          }
        },
        () => {
          this._gAService.error("appointment_addcomment");
          this.onAppointmentStatusEvents.next({
            action: E_Appointment_Event.ERROR_ADDING_COMMENT,
          });
        }
      );
  }

  /**
   * Gets appointments but only once per "when" in any given 5 seconds
   *
   * This is to allow multiple components to call the getAppointments method without triggering
   * multiple requests to GraphQL.
   */
  private _listenForGetAppointments(): void {
    this._subs.sink = this._getAppointmentsState
      .pipe(
        distinctUntilChanged(),
        filter((when) => when !== null),
        switchMap((when) => {
          let query = when === E_Appointments_When.FUTURE ? FUTURE_QUERY : PAST_QUERY;
          if (this._jwtService.isPatientUnauthenticated()) {
            // Get just the ID of the appointment so that we can count the number of appointments for the UI
            query = `{
              appointments(when: "${when}") {
                items {
                  id
                  practitioner {
                    first_name
                    last_name
                  }
                }
              }
            }`;
          }

          return this._httpService.query<AppointmentEntry>(GRAPHQL_OPERATION_NAMES.APPOINTMENTS, query).pipe(
            tap((response) => {
              if (response.errors) {
                this.onAppointmentsChanged.next({
                  status: E_Appointment_Updated.ERROR,
                });
                return;
              }
              const result: Array<AppointmentEntry> = response.data.appointments.items.map((appointment: AppointmentBase) => {
                if (appointment.practitioner) appointment.practitioner = new AppointmentPractitionerEntry(appointment.practitioner);
                return new AppointmentEntry(appointment);
              });
              this._appointments = result;
              this.checkForCheckins(result);
              this.onAppointmentStatusEvents.next({
                action: E_Appointment_Event.APPOINTMENTS_UPDATED,
                appointments: this._appointments,
              });
              this.onAppointmentsChanged.next({
                status: E_Appointment_Updated.SUCCESS,
                when,
                appointments: this._appointments,
              });
            }),
            catchError(() => {
              // todo: error handling
              this.onAppointmentsChanged.next({
                status: E_Appointment_Updated.ERROR,
              });

              return of(null);
            })
          );
        })
      )
      .subscribe(() => {
        // Reset the state after 5 seconds to allow appointments to be requested again
        timer(5000).subscribe(() => this._getAppointmentsState.next(null));
      });
  }

  public cancelAppointment(appointment: AppointmentEntry): Observable<boolean> {
    //ensure the rebooking property is set to true for the metrics for the next appointment booking
    this._appointmentAnalyticsService.addCancelledReason(appointment.reason);
    return this._httpService
      .mutation(
        "cancelAppointment",
        `{
      cancelAppointment(id: "${appointment.id}")
    }`
      )
      .pipe(
        catchError((err) => {
          return of({
            errors: [err],
          });
        }),
        map<
          {
            errors: Array<any>;
            data?: {
              cancelAppointment: boolean;
            };
          },
          boolean
        >((response: any) => {
          if (response.errors) return false;

          if (response.data) return response.data.cancelAppointment;
        })
      );
  }

  public clearKeepAlive(): void {
    if (this._keepAliveSubscription) this._keepAliveSubscription.unsubscribe();
  }

  /**
   * Extend the held appointments 10 seconds before they expire to allow for network latency etc
   */
  private _startKeepAlive(): void {
    const heartbeatInterval = 50;
    /*
      Calculate the number of heartbeats required to keep the appointments held for 30 minutes. After
      30 minutes the appointments will be released because it's likely that the patient has been
      distracted. If the patient continues to interact with the page then the appointments will be
      created if the slot is still available and no deposit is required else the patient will see an
      error which will prompt them to try again. This will prevent the appointment being held
      indefinitely if the patient leaves the tab open and it continues to extend the held appointments
    */
    const heartbeatLimit = Math.ceil((30 * 60 - this._sessionTimeout) / heartbeatInterval);

    this.clearKeepAlive();
    this._keepAliveSubscription = timer((this._sessionTimeout - 10) * 1000, heartbeatInterval * 1000)
      .pipe(take(heartbeatLimit))
      .subscribe(async () => {
        if (this._heldAppointments.length) {
          await this.extendHeldAppointments();
        } else {
          this._keepAliveSubscription.unsubscribe();
        }
      });
  }

  private get _sessionTimeout(): number {
    return this._jwtService.isPatient() ? APPOINTMENT_BOOKING.EXISTING_PATIENT_TIMEOUT_IN_SECONDS : APPOINTMENT_BOOKING.NEW_PATIENT_TIMEOUT_IN_SECONDS;
  }

  public async noResponseToBookingSessionTimeout(): Promise<void> {
    await this.clearHeldAppointments();
    window.location.reload();
  }

  public async waitForBookingSession(): Promise<void> {
    await firstValueFrom(
      race(
        this.onAppointmentStatusEvents.pipe(filter((event) => event?.action !== E_AppointmentEventActions.CHECKING_BOOKING_SESSION)),
        this.onBookingError.pipe(filter((error) => error === true))
      ).pipe(take(1))
    );
  }

  public retrieveCheckInAppointmentDetails(appointmentId: string): Observable<{ data: { appointment: CheckInAppointmentDetails } } | { errors: string[] }> {
    return this._httpService
      .query(
        GRAPHQL_OPERATION_NAMES.APPOINTMENT,

        `
{
  appointment(appointment_id: "${appointmentId}"){
    state
    system_state
    reason
    duration
    start_time
    patient {
      first_name
    }
    practitioner {
      first_name
      last_name
      site_name
      image_url
      site_address_line_1
      site_address_line_2
      site_address_line_3
      site_county
      site_postcode
      role
    }
  }
}`
      )
      .pipe(
        catchError((err) => {
          return of({
            errors: [err],
          });
        })
      );
  }
}
