<dentr-notification></dentr-notification>
<div class="h-full px-4">
  <div
    @fadeInAnimation
    *ngIf="isOverlayOpen"
    aria-hidden="true"
    (click)="closeOverlay()"
    aria-hidden="true"
    class="fixed bottom-0 top-0 right-0 left-0 z-40 bg-gray-900/50"
  ></div>
  <dentr-main [appView]="appView" *ngIf="!loading" class="relative z-30"></dentr-main>
  <dentr-chat></dentr-chat>
</div>
